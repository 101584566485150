import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux-store";
import { editPromoApi, getCompanyApi, postCompanyApi } from "./api";
import {
    makeBaseReduxAction,
    makeClearCondition,
    makeClearPaginator,
    makeConditionReducers,
    Paginator,
} from "helper/redux";
import { Expo } from "controllers/admin-expo/interfaces/admin-expo";
import {RegistrationRole} from "constants/constants";

export type Promo = {
    id: number;
    code: string;
    description: string;
    cost: number;
    deleted_at: string;
    created_at: string;
    updated_at: string;
    max_count: number;
    usage_count: number;
    role: number;
    exponent: boolean;
};

export type Company = {
    address: string;
    bitrix_id: any;
    bitrix_state: any;
    created_at: any;
    deleted_at: any;
    employees_count: number;
    expo_place: number;
    id: number;
    inn: string;
    kpp: string;
    name: string;
    short_name: string;
    ogrn: string;
    parent_id: any;
    promos: Array<Promo>;
    promos_parsed: any;
    updated_at: any;
    user_id: number;
    exponent?: Expo;
    employee: any;
};

const PR = RegistrationRole.PARTICIPANT_PREMIUM;
const ER = RegistrationRole.EXPONENT;
export const INIT_PARSED_PROMOS = { [PR]: [], [ER]: [] } as any;
export const parsePromos = (company:any) => {
    let tmp = {...INIT_PARSED_PROMOS};
    company?.promos?.forEach((p:any) => {
        tmp = ({...tmp,
            [p.role]:   [...(tmp[p.role] ||[]), p],
            max:        {...(tmp.max ||{}), [p.role]:  (tmp.max?.[p.role] || 0) + p.max_count},
            use:        {...(tmp.use ||{}), [p.role]:  (tmp.use?.[p.role] || 0) + p.usage_count},
        })
        tmp.free = {
            prem: Math.max(0, (tmp.max?.[PR]||0) - (tmp.use?.[PR]||0)),
            expo: Math.max(0, (tmp.max?.[ER]||0) - (tmp.use?.[ER]||0)),
        }
    });
    return tmp
}

export const slicer_Companies = createSlice({
    name: "companies",
    initialState: {
        paginator: makeClearPaginator(),
        items: [],
        company: {} as Company,
        condition: makeClearCondition(),
    },
    reducers: {
        ...makeConditionReducers(),
        onLoadItem: (state, action) => {
            let val = action.payload.response.data;
            val.promos_parsed = parsePromos(val);
            state.company = val;
        },
        onLoadList: (state, action) => {
            state.items = action.payload.response.data.map((v:any )=> {
                v.promos_parsed = parsePromos(v);
                return v;
            });
            state.paginator = {...action.payload.nativeResponse.body };
        },
    },
});

export const { onRequest, onNoticeHide, stopLoading, onLoadList, onLoadItem, onFail, clearCondition } =
    slicer_Companies.actions;

const baseStateAsync = makeBaseReduxAction(
    (dispatch: any) => {
        dispatch(onRequest());
    },
    (dispatch, r) => {
        if (r) {
            dispatch(onFail(r));
        }
    },
    dispatch => {
        dispatch(stopLoading());
    },
);

export const asyncLoadCompany = (params?: any, callback?: (result: any) => void) => {
    return baseStateAsync(getCompanyApi, params, callback, (dispatch, result) => {
        dispatch(params?.id ? onLoadItem(result) : onLoadList(result));
    });
};

export const asyncSaveCompany = (body: any, callback?: (result: any) => void) => {
    return baseStateAsync(postCompanyApi, body, callback, (dispatch, result) => {
        result?.response.result && dispatch(clearCondition())
        dispatch(onLoadItem(result))
    });
};

export const asyncPromoEdit = (
    params?: { id: number; expo: number; premium: number },
    callback?: (result: any) => void,
) => {
    return baseStateAsync(editPromoApi, params, callback, (dispatch, result: any) => {});
};

export const reselectCompany = (state: RootState) => {
    return state.companies.account.company;
};
export const reselectCompanyCondition = (state: RootState) => {
    return state.companies.account.condition;
};
export const reselectCompanies = (state: RootState) => {
    return state.companies.account.items;
};
export const reselectCompaniesPaginator = (state: RootState) => {
    return state.companies.account.paginator;
};

export default slicer_Companies.reducer;
