import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { Color } from "@material-ui/lab/Alert";
import {
    apiFrontContent,
    apiFrontContentLocaleUpdate,
} from "controllers/locale/api/front-content-locale/front-content-locale";
import __locale from "../../../../helper/location/location";
import { LangEnum } from "../../../admin-expo/enums";


const RuContent = require("./ru-content");

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        data: any;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};


const toObject = (arr: Array<any>) => {
    const result = {};
    arr.forEach(record => {
        // @ts-ignore
        result[record.key] = record.by_locale;
    });
    return result;
};
export const slicerFrontContentLocale = createSlice({
    name: "front-content-locale",
    initialState: {
        locale: LangEnum.RU as string,
        list: toObject(__locale() === LangEnum.RU ? RuContent : []) as Record<any, any>,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                message: "",
            },
        } as Response,
        loaded: true,
    },
    reducers: {
        onConditionClear: state => {
            state.condition = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
            };
        },
        onRequest: state => {
            state.condition.inProgress = true;
            state.loaded = false;
        },
        onNoResponse: state => {
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    message: "",
                },
                notice: {
                    severity: "error",
                    visibility: true,
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<string>) => {
            state.condition.inProgress = false;
            if (typeof action.payload === "string") {
                state.condition.response = {
                    result: false,
                    data: null,
                    errors: null,
                    message: action.payload,
                };
                state.condition.notice = {
                    severity: "error",
                    visibility: true,
                };
            }
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                data: Array<any>;
                message: string;
                result: boolean;
            }>,
        ) => {
            state.list = Array.isArray(action.payload.data) ? toObject(action.payload.data) : {};
            state.locale = __locale();
            localStorage.setItem(
                "content_" + __locale(),
                JSON.stringify({
                    data: state.list,
                    time: new Date().getTime(),
                }),
            );
            state.condition = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: null,
                    message: action.payload.message,
                    errors: null,
                },
                notice: {
                    severity: "success",
                    visibility: true,
                },
            };
            state.loaded = true;
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        updateList: (state, action: PayloadAction<any>) => {
            state.list = { ...action.payload };
        },
    },
});

export const {
    onConditionClear,
    onRequest,
    onNoResponse,
    onResponseSuccess,
    onResponseFail,
    onNoticeHide,
    updateList,
} = slicerFrontContentLocale.actions;

export const asyncFrontContentByLocale = (
    localeCode: string,
    callback?: (result: any) => void,
    page?: number,
    params?: any,
) => async (dispatch: any) => {
    let cache = JSON.parse(localStorage.getItem("content_" + localeCode) || "{}");
        if (!page && cache?.data && cache?.time && new Date().getTime() - cache?.time < 3600000) {
        let result = {
            result: true,
            data: cache.data,
            message: "from cache",
        };
        dispatch(updateList(cache.data));
        return callback && callback(result);
    }
    if (!page) dispatch(onRequest());
    const result = await apiFrontContent(localeCode, page, params).catch(e => {
        console.log(e);
    });
    if (!page) {
        if (result) {
            dispatch(result.result ? onResponseSuccess(result) : onResponseFail(result));
        } else {
            dispatch(onNoResponse());
        }
    }
    if (callback) {
        callback(result);
    }
};

export const reselectFrontContentLocaleList = (state: RootState): Record<string, string> => {
    return state.frontContentLocale.list;
};
export const reselectFrontContentLocale = (state: RootState): string => {
    return state.frontContentLocale.locale;
};
export const reselectFrontContentLocaleCondition = (state: RootState): Response => {
    return state.frontContentLocale.condition;
};

export default slicerFrontContentLocale.reducer;
